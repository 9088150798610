import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../services/loginSlice";


const Protected = (props) => {
    const {Component} = props
    const isLogin = useSelector(isLoggedIn)
    
    if(!isLogin) {
        return <Navigate to="/" />
    }
    
    return <Component />
    
}

export default Protected