import React, {Suspense, lazy} from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import Header  from "./AdminHeader";
import Protected from "./Protected";

const Login = lazy(() => import('../container/AdminLogin'))
const ForgotPassword = lazy(() => import('../container/ForgotPassword'))
const ResetPassword = lazy(() => import('../container/ResetPassword'))
const Dashboard = lazy(() => import('../container/AdminDashboard'))
const RetailerList = lazy(() => import('../container/RetailerList'))
const RetailerDetail = lazy(() => import('../container/RetailerDetail'))
const TeamMembers = lazy(() => import('../container/TeamMember'))
const TeamMemberDetail = lazy(() => import('../container/TeamMemberDetail'))
const Settings = lazy(() => import('../container/Settings'))
const NotFound = lazy(() => import('./AdminNotFound'))

const AdminLayout = () => (
    <div className="adminwrapper">
        <Header />
        <Outlet />
        {/* <div className="adminsubwrapper">
            <div className="adminleft border-end">
                <AdminLeftMenu />
            </div>
            <div className="adminright">
            <Outlet />
            </div>
        </div> */}
    </div>
);

const Layout = () => {
    return (
        <>            
            <Suspense fallback={<div className="align-self-center container text-center">Loading...</div>}>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/" element={<AdminLayout />}>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/dashboard" element={<Protected Component={Dashboard} />} />
                    <Route path="/retailers" element={<Protected Component={RetailerList} />} />
                    <Route path="/retailerdetail/:id" element={<Protected Component={RetailerDetail} />} />
                    <Route path="/teammembers" element={<Protected Component={TeamMembers} />} />
                    <Route path="/teammemberdetail/:id" element={<Protected Component={TeamMemberDetail} />} />
                    <Route path="/settings" element={<Protected Component={Settings} />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                
                {/* <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/*" element={<NotFound />} />
                <Route path="retailers" element={<RetailerList />} />
                <Route path="retailerdetail/:id" element={<RetailerDetail />} />
                <Route path="teammemberdetail/:id" element={<TeamMemberDetail />} /> */}
            </Routes>
            </Suspense>
        </>
    )
}

export default Layout