import React from 'react';
import './assets/scss/style.scss'
import Layout from './component/Layout'

function App() {
  return (
    <Layout />
  );
}

export default App;
