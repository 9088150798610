import axios from "axios";

const authHeaders = () => {
    // const hasAuth = localStorage.getItem('persist:livelyretailer')
    // if(hasAuth){
    //     const { login } = JSON.parse(hasAuth)
    //     const user = JSON.parse(login)
    //     console.log(user.token)
    //     if(login && user && user.token) {
    //         return{
    //             'content-type': 'application/json',
    //             'Authorization': `${user.token}`
    //         }
    //     }
    //     else {
    //         return { 'content-type': 'application/json'}    
    //     }
    // }
    // else { 
    //     return { 'content-type': 'application/json'}
    // }
    return { 'content-type': 'application/json'}
    
}

export default axios.create({
    baseURL:process.env.REACT_APP_BASE_URL,
    headers : authHeaders()
})