import React from "react";
import { ReactComponent as Logo } from '../assets/images/logo.svg'
import { Navbar, Container, Button, Nav } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/scss/adminstyle.scss";
import { useDispatch } from "react-redux";
import { makeLogout, logout } from "../services/loginSlice";

const AdminHeader = () => {

    const {pathname} = useLocation()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const logoutHandler = (e) => {
        e.preventDefault()
        dispatch(makeLogout())
        .then(()=>{
            dispatch(logout())
            navigate('/')
        })
    }
    
    if(pathname === '/reset-password' ||  pathname === '/forgotpassword') {
        return(
            <>
                <Navbar collapseOnSelect expand="lg">
                    <Container>
                        <Navbar.Brand as={NavLink} to="/" className="col-md-2"><Logo /></Navbar.Brand>
                    </Container>
                </Navbar>
            </>
        )
    }

    return(
        <>
            <Navbar collapseOnSelect expand="lg">
                <Container className="justify-content-between">
                    <Navbar.Brand as={NavLink} to="dashboard"><Logo /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="dashmenu">
                            <Nav.Link as={NavLink} to="/dashboard">Dashboard</Nav.Link>
                            <Nav.Link as={NavLink} to="/retailers">Retailers</Nav.Link>
                            <Nav.Link as={NavLink} to="/teammembers">Team Members</Nav.Link>
                            <Nav.Link as={NavLink} to="/settings">Settings</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="d-flex align-items-center me-2">
                        {/* <div className="adminprofilepic  me-3">
                            <span className="d-none d-md-flex text-black-50 fs-7 me-2">Welcome Admin</span>
                            <span className="imgbox"><img src={placeholder} alt="Profile" /></span>
                        </div> */}
                        <Button variant="outline-primary" onClick={logoutHandler}>Sign Out</Button>
                    </div>
                </Container>
            </Navbar>
        </>
    )
}

export default AdminHeader