import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    teamListData: '',
    teamMemberDetailData:'',
}

export const allTeamMember = createAsyncThunk(
    'allTeamMember/retailer',
    async () => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/admin/getAll`, {headers: headers})
        return response.data
    }
)

export const teamMemberDetail = createAsyncThunk(
    'teamMemberDetail/retailer',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/admin/user/${data}`, {headers: headers})
        return response.data
    }
)

export const teamMemberSlice = createSlice({
    name:'teammember',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(allTeamMember.pending, (state) => {
                state.status = 'loading'
                state.error = ''
            })
            .addCase(allTeamMember.fulfilled, (state, action) => {
                state.status = 'true'
                state.teamListData = action.payload
                state.error = ''
            })
            .addCase(allTeamMember.rejected, (state, action) => {
                state.status = 'false'
                state.teamListData = ''
                state.error = action.error.message
            })
            .addCase(teamMemberDetail.pending, (state) => {
                state.status = 'loading'
                state.error = ''
            })
            .addCase(teamMemberDetail.fulfilled, (state, action) => {
                state.status = 'true'
                state.teamMemberDetailData = action.payload
                state.error = ''
            })
            .addCase(teamMemberDetail.rejected, (state, action) => {
                state.status = 'false'
                state.teamMemberDetailData = ''
                state.error = action.error.message
            })
    }
})

export default teamMemberSlice.reducer