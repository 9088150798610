import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    error: '',
    retailerDetailData:'',
}

export const retailerDetail = createAsyncThunk(
    'retailerDetail/retailer',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }
        const response = await axios.get(`/admin/retailer/${data}`, {headers: headers})
        return response.data
    }
)

export const retailerSlice = createSlice({
    name:'retailer',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(retailerDetail.pending, (state) => {
                state.status = 'loading'
                state.error = ''
            })
            .addCase(retailerDetail.fulfilled, (state, action) => {
                state.status = 'true'
                state.retailerDetailData = action.payload
                state.error = ''
            })
            .addCase(retailerDetail.rejected, (state, action) => {
                state.status = 'false'
                state.retailerDetailData = ''
                state.error = action.error.message
            })
    }
})

export default retailerSlice.reducer