import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import loginReducer from '../services/loginSlice';
import dashboardReducer from '../services/dashboardSlice';
import passwordReducer from '../services/passwordSlice';
import retailerReducer from '../services/retailerSlice';
import teammemberReducer from '../services/teammemberSlice';

const persistConfig = {
  key: 'livelysuperadmin',
  version: 1,
  storage,
  blacklist: ['shopper']
}

const appReducer = combineReducers({
  login: loginReducer,
  dashboard: dashboardReducer,
  forgotpassword: passwordReducer,
  retailer:retailerReducer,
  teammember: teammemberReducer 
})

const rootReducer = (state, action) => {
  if (action.type === 'login/logout') {
    state = undefined;
    persistConfig.storage.removeItem('persist:livelysuperadmin')
    localStorage.clear()
    console.log('if', state)
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store)
console.log('intial state', store.getState())