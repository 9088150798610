import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "./axios";

const initialState = {
    status: 'Idle',
    isLogin : false,
    error: '',
    token: '',
    userData : {},
    getuserData: ''
}

export const makeLogin = createAsyncThunk(
    'makeLogin/login',
    async (data) => {
        const response = await axios.post(`/admin/login`, data)
        return response.data
    }
)

export const makeLogout = createAsyncThunk(
    'makeLogout/login',
    async (data) => {
        const hasAuth = JSON.parse(localStorage.getItem('token'))
        const headers = {
        'Content-Type': 'application/json',
        'Authorization' : `${hasAuth}`
        }   
        const response = await axios.post(`/admin/logout`, data, {headers:headers})
        return response.data
    }
)

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
      logout: state => {
        localStorage.removeItem("token")
        state.isLogin = false
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(makeLogin.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeLogin.fulfilled, (state, action) => {
            state.status = 'true'
            state.isLogin = action.payload.status === 'success' ?  true : false
            state.token = action.payload.data.token ? action.payload.data.token : ''
            state.userData = action.payload
            state.error = ''
            localStorage.setItem("token", JSON.stringify(state.token))
          })
          .addCase(makeLogin.rejected, (state, action) => {
            state.status = 'false'
            state.isLogin = false
            state.userData = {}
            state.error = action.error.message
          })
          .addCase(makeLogout.pending, (state) => {
            state.status = 'loading'
            state.error = ''
          })
          .addCase(makeLogout.fulfilled, (state, action) => {
            state.status = 'true'
            state.isLogin = false
            state.userData = {}
            state.token = ''
            state.error = ''
            localStorage.removeItem("token")
          })
          .addCase(makeLogout.rejected, (state, action) => {
            state.status = 'false'
            state.isLogin = false
            state.error = action.error.message
          })
            
    },
})
export const { logout } = loginSlice.actions
export const isLoggedIn = (state) => state.login.isLogin
export default loginSlice.reducer